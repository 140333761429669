import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import offer_gif from "../../../images/Comingsoon.gif";
import loading_img from "../../../images/profile/loading.gif";
import Broker from "../../../images/icon/Broker.png";
import Game from "../../../images/icon/Game.png";
import Social_Media from "../../../images/icon/Social_Media.png";
import Node from "../../../images/icon/Node.png";
import Coin_Listing from "../../../images/icon/Coin_Listing.png";
import Airdrop from "../../../images/icon/Airdrop.png";
import Real_Estate from "../../../images/icon/Real_Estate.png";
import Metaverse from "../../../images/icon/Metaverse.png";
import NFT from "../../../images/icon/NFT.png";
import Bill_Pay from "../../../images/icon/Bill_Pay.png";
import Galaxy_Debit_Card from "../../../images/icon/Galaxy_Debit_Card.png";
import Galaxy_Exchanger from "../../../images/icon/Galaxy_Exchanger.png";




import Deposit from "../../../images/icon/Deposit.png";
import ToInvite from "../../../images/icon/ToInvite.png";
import Community from "../../../images/icon/Community.png";
import Asset from "../../../images/icon/Asset.png";
import Withdraw from "../../../images/icon/Withdraw.png";
import Convert from "../../../images/icon/Convert.png";
import swap from "../../../images/icon/swap.png";
import Security from "../../../images/icon/Security.png";
import MyTeam from "../../../images/icon/MyTeam.png";
import P2P from "../../../images/icon/P2P.png";
import Website from "../../../images/icon/Website.png";
import Plan_PDF from "../../../images/icon/pdf.png";

import { GlobalSet } from '../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
const Home = () => {
    document.title = "X-Express Home";
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "lite", label: "Lite" });
    }, []);
  const { BaseUrl } = GlobalSet();
  const [tradingWallet, setTradingWallet] = useState(0);
  const [tradestatus, setTradeStatus] = useState([]);
  let incrementInterval;
  const [loading, setLoading] = useState(true);

  const incrementTradingWallet = () => {
    const randomFactor = (Math.random() - 0.5) * 0.8 / 100;
    setTradingWallet(prevValue => parseFloat((prevValue + prevValue * randomFactor).toFixed(2)));
  };


  useEffect(() => {
    if (!loading && tradestatus === 1) {
      incrementInterval = setInterval(incrementTradingWallet, 1000);
    }
    return () => {
      clearInterval(incrementInterval);
    };
  }, [loading, tradestatus]);

   const [homedata, setHomeData] = useState([]);  
   const [salider, setSalider] = useState([]);  
  /*.....product code........*/
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setSalider(response.data.slider)
         setHomeData(response.data.data_info)
         setTradingWallet(response.data.trading_wallet)
         setTradeStatus(response.data.trade_status)
         localStorage.setItem('coin_wait', response.data.coin_wait);
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);

    
//mobile odds option
  const casino_game_s = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };

return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">

        <div className="row">
            <Fragment>


             <Carousel className="p-3">
                {salider.map((carousel, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={BaseUrl+(carousel.img_link)}
                      className='d-block w-100'
                      alt={`Slide ${i + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel> 

           <Col xl="12" className="p-0" >

                  <div className="container">

                    <div className="row d-flex justify-content-center">


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/deposit">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Deposit} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Deposit</h6>
                        </div>
                       </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/affiliate">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={ToInvite} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />    
                          <h6 className="mt-1" style={{color: '#002f6a', fontSize: '10px'}}>To Invite</h6>                 
                        </div>
                         </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/community">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Community} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />                
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Community</h6>
                        </div>
                        </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/app-asset">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Asset} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />    
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Asset</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/withdraw">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Withdraw} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />            
                          <h6 className="mt-1"  style={{color: '##002f6a', fontSize: '10px'}}>Withdraw</h6>
                        </div>
                         </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/convert-balance">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Convert} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />     
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Convert</h6>
                        </div>
                       </Link>
                      </div>

                    



                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/swap">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={swap} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />    
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Swap</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/security">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Security} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />    
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Security</h6>
                        </div>
                       </Link>
                      </div>




                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/all-team-users">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={MyTeam} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />                  
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>My Team</h6>
                        </div>
                       </Link>
                      </div>



                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/balance-transfer">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={P2P} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />      
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>P2P</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">                         <a href="https://www.xexpress.io/" target="_blank" rel="noopener noreferrer">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Website} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />      
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Website</h6>
                        </div>
                       </a>
                      </div>

                      
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                         <a href="https://www.xexpress.io/X-express.pdf" target="_blank" rel="noopener noreferrer">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                          <img src={Plan_PDF} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />     
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Plan PDF</h6>
                        </div>
                       </a>
                      </div>


                    </div>
                  </div>
             </Col>


                 <div className="container">
                   <div className="row d-flex justify-content-center mt-3" style={{textAlign : 'center'}}>
                     <img style={{ width:"90%", margin: "0px auto" }} className="form-inline" src={offer_gif} alt=""/>
                   </div>
                 </div>

                  <div className="container">
                    <div className="row d-flex justify-content-center mt-3">


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Game} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Game</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Coin_Listing} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Node</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Broker} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Broker</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Social_Media} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Social <br/> Media</h6>
                        </div>
                       </Link>
                      </div>



                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Bill_Pay} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Bill Pay</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Node} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Coin  <br/> Listing</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Airdrop} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Airdrop</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Real_Estate} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Real  <br/> Estate</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={NFT} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>NFT</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Metaverse} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Metaverse</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Galaxy_Debit_Card} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Debit  <br/> Card</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid #002f6a' }}>
                        <img src={Galaxy_Exchanger} className="img-fluid rounded-circle" alt="profile" width="32" height="32" />
                          <h6 className="mt-1"  style={{color: '#002f6a', fontSize: '10px'}}>Exchanger</h6>
                        </div>
                       </Link>
                      </div>
                    


                    </div>
                  </div>



          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;